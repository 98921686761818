import * as React from "react";
import { Link } from "gatsby";

import Layout from "../components/Layout";
import Title from "../components/Title";

const IndexPage = ({ path }) => (
  <Layout currentPath={path} title="Contact Us">
    <Title label="Sorry" />

    <section className="px-4 py-8 container mx-auto">
      <p className="my-4 text-lg text-gray-700 dark:text-gray-300">
        Sorry, we couldn't find what you are looking for.
      </p>

      <div className="flex">
        <Link
          className="text-primary-600 font-semibold text-lg dark:text-primary-200"
          to="/"
        >
          Back to Home &#8594;
        </Link>
      </div>
    </section>
  </Layout>
);

export default IndexPage;
